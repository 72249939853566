//
// rating.scss
//

.rating {
  display: inline-flex;
  margin-bottom: 0;
}

.rating:not([data-value]) .rating-item {
  color: $rating-color;
}

.rating[data-value="1"] .rating-item:nth-child(n + 2) {
  color: $rating-color;
}

.rating[data-value="2"] .rating-item:nth-child(n + 3) {
  color: $rating-color;
}

.rating[data-value="3"] .rating-item:nth-child(n + 4) {
  color: $rating-color;
}

.rating[data-value="4"] .rating-item:nth-child(n + 5) {
  color: $rating-color;
}

.rating[data-value="5"] .rating-item:nth-child(n + 6) {
  color: $rating-color;
}


// Form

.rating-form {
  position: relative;
  display: inline-flex;
}

.rating-input {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
