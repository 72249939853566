//
// video.scss
//

.video-512 {
  object-fit: cover;

  @supports (object-fit: cover) and (not(-ms-ime-align:auto)) {
    width: 100%;
    height: 512px;
  }
}
