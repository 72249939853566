.main-logo{
  max-height: 60px;
}
.ti-filter{
  font-size: 15px;
  margin: 5px;
}
.navbar-nav.flex-row{
  li{
    //border: solid 1px #f00;
    min-width: 40px;
  }
}