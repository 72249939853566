//
// grid.scss
//

// Cover

.container-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;

  > [class*="container"],
  > [class*="container"] > .row {
    height: inherit;
  }
}


// Offset

@for $i from 1 through $grid-columns {
  .offset-n#{$i} {
    margin-left: -(100% / $grid-columns * $i);
  }
}

@each $breakpoint, $value in $grid-breakpoints {
  @if $breakpoint !='xs' {
    @include media-breakpoint-up(#{$breakpoint}) {
      @for $i from 1 through $grid-columns {
        .offset-#{$breakpoint}-n#{$i} {
          margin-left: -(100% / $grid-columns * $i);
        }
      }
    }
  }
}
