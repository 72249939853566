// 
// user.scss
// Use this to write your custom SCSS
//
.c-pointer {
  cursor: pointer;
}
.c-cross {
  cursor: not-allowed;
}
.btn-invisible{
  border: solid 0 white;
  background-color: white;
}
.spinner-border{
  font-size: 10px !important;
  max-height: 17px !important;
  max-width: 17px !important;
  margin-left: 15px;
}

.score-star{
  margin-left: 2px;
  margin-right: 2px;
}

.text-size-error{
  font-size: 13px;
  font-style: italic;
}

.btn-outline-primary{
  color: $primary;
}

.page-container{
  min-height: 100vh;
}

.full-page-loader{
  height: 400px;
  text-align: center;
}
body, html{
  max-height: 100vh !important;
  height: 100vh;
}
.fs-12{
  font-size: 14px;
}
.btn-top{
  border: solid 1px #666 !important;
  border-radius: 13px;
  background-image: url("../assets/dekbed/chevron-up.svg") !important;
  width: 26px !important;
  height: 26px !important;
  background-size: 70%;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.heart-filled-icon{
  width: 15px;
  height: 15px;
}
.heart-filled-icon-product{
  width: 20px;
  height: 20px;
}

.form-check-label.active{
  color: #000!important;
  border-color: #000!important;
}

.color-block{
  border-radius: 2px !important;
  .group-color-element {
    border-radius: 2px !important;
    position: relative;
    &.active:after {
      display: inline-flex;
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 3px;
      border-top: 1px solid #999;
      background-color: #000;
    }
  }
}